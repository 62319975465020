import React from "react";
import { Table } from "react-bootstrap";
import styles from "./ServicesTable.module.css";
import { Link } from "react-router-dom";

const ServicesTable = ({ Data, image }) => {
  return (
    <Table className={styles.table} striped>
      <thead>
        <tr>
          <th>#</th>
          <th>Admin Services</th>
          {image && <th>Image</th>}
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {Data.map((item) => (
          <tr>
            <td className={styles.checkBox}>
              <input type="checkbox" />
            </td>
            <td style={{ width: !image && "74%" }} className={styles.services}>
              {item.serviceName}
            </td>
            {image && (
              <td className={styles.image}>
                <img src={item.img} />
              </td>
            )}
            <td className={styles.action}>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/dashboard/servicesmanagement/edit"
              >
                <div className={styles.edit}>
                  <img src="/images/icons/editIcon.svg" />
                  <h3 className="h3_main">Edit</h3>
                </div>
              </Link>
              <div className={styles.add}>
                <img src="/images/icons/deleteIcon.svg" />
                <h3 className="h3_main">Remove</h3>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ServicesTable;
