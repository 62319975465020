export const Data = [
    {
      name: "ID: 43765",
      phone: "Vice President",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 45765",
      phone: "Trust Office",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 73765",
      phone: "Tax Office",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 63765",
      phone: "Agent",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 53765",
      phone: "Regional Manager",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 33172",
      phone: "Accountant",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 93172",
      phone: "Vice President",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 93172",
      phone: "Vice President",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 93172",
      phone: "Vice President",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 93172",
      phone: "Vice President",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 93172",
      phone: "Vice President",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
    {
      name: "ID: 93172",
      phone: "Vice President",
      status: "",
      link: "/dashboard/jobsmanagement/view",
    },
  ];
  