import React from "react";
import styles from "./ServiceProvidersTable.module.css";
import { Table } from "react-bootstrap";

const ServiceProvidersTable = ({ Data }) => {
  return (
    <Table className={styles.table} striped>
      <thead>
        <tr>
          <th>#</th>
          <th>Request ID</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {Data.map((item) => (
          <tr>
            <td className={styles.checkBox}>
              <input type="checkbox" />
            </td>
            <td className={styles.id}>{item.id}</td>
            <td className={styles.amount}>{item.amount}</td>
            <td style={{color: item.status=== "Pending" ? "red" : "blue"}} className={styles.status}>{item.status}</td>
            <td className={styles.action}>
              <select className={styles.customSelect}>
                <option value="pending">Pending</option>
                <option value="completed">Paid</option>
              </select>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ServiceProvidersTable;
