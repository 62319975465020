export const Data = [
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
    {
      serviceName: "Furniture",
    },
  ];
  