export const Data = [
  {
    name: "Mark Wick",
    phone: "(441) 645-8966",
    status: "",
    link: "/dashboard/customer/view",
  },
  {
    name: "April Curtis",
    phone: "(441) 645-8966",
    status: "",
    link: "/dashboard/customer/view",
  },
  {
    name: "Mike Torello",
    phone: "(441) 645-8966",
    status: "",
    link: "/dashboard/customer/view",
  },
  {
    name: "John",
    phone: "(441) 645-8966",
    status: "",
    link: "/dashboard/customer/view",
  },
  {
    name: "William",
    phone: "(441) 645-8966",
    status: "",
    link: "/dashboard/customer/view",
  },
  {
    name: "Tony Danza",
    phone: "(441) 645-8966",
    status: "",
    link: "/dashboard/customer/view",
  },
  {
    name: "Tony Danza",
    phone: "(441) 645-8966",
    status: "",
    link: "/dashboard/customer/view",
  },
  {
    name: "Tony Danza",
    phone: "(441) 645-8966",
    status: "",
    link: "",
  },
  {
    name: "Tony Danza",
    phone: "(441) 645-8966",
    status: "",
    link: "",
  },
  {
    name: "Tony Danza",
    phone: "(441) 645-8966",
    status: "",
    link: "",
  },
  {
    name: "Tony Danza",
    phone: "(441) 645-8966",
    status: "",
    link: "",
  },
  {
    name: "Tony Danza",
    phone: "(441) 645-8966",
    status: "",
    link: "",
  },
];
