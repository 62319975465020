import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import styles from "./ServicesManagementMainScreen.module.css";
import Pagination from "../../../components/UI Helpers/Pagination/Pagination";
import ServicesTable from "../../../components/ServicesTable/ServicesTable";
import { Data } from "./ServicesData";
import { Link } from "react-router-dom";

const ServicesManagementMainScreen = () => {
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <Header getIsActive={(e) => setIsActive(e)} />
      <div
        style={{
          width: "100%",
          height: "92vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sidebar barActive={isActive} />
        <div className={styles.main}>
          <div className={styles.head}>
            <h1 className="h2_main">Services Category</h1>
            <Link to="/dashboard/servicesmanagement/add">
              <div className={styles.headButton}>
                <img alt="" src="/images/icons/plus.svg" />
              </div>
            </Link>
          </div>
          <div className={styles.body}>
            <ServicesTable Data={Data} image={true} />
          </div>
          <Pagination />
        </div>
      </div>
    </>
  );
};

export default ServicesManagementMainScreen;
