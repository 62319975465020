import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import styles from "./CustomerScreen.module.css";
import Pagination from "../../../components/UI Helpers/Pagination/Pagination";
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import {Data} from "./customersData"

const CustomerScreen = () => {
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <Header getIsActive={(e) => setIsActive(e)} />
      <div
        style={{
          width: "100%",
          height: "92vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sidebar barActive={isActive} />
        <div className={styles.main}>
          <h1 className="h2_main text-start">Customers</h1>
          <div className={styles.body}>
            <DynamicTable Data={Data}/>
          </div>
          <Pagination />
        </div>
      </div>
    </>
  );
};

export default CustomerScreen;
