import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ServicesTable from "../../../components/ServicesTable/ServicesTable";
import styles from "./ServicesManagementAddScreen.module.css";
import Pagination from "../../../components/UI Helpers/Pagination/Pagination";
import { Data } from "./ServicesData";
import { Form } from "react-bootstrap";

const ServicesManagementAddScreen = () => {
  const [isActive, setIsActive] = useState(true);
  return (
    <>
      <Header getIsActive={(e) => setIsActive(e)} />
      <div
        style={{
          width: "100%",
          height: "92vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sidebar barActive={isActive} />
        <div className={styles.main}>
          <div className={styles.head}>
            <h1 className="h2_main text-start align-self-start">
              Add Services To Homepage
            </h1>
            <div className={styles.inputCont}>
              <Form className={styles.form}>
                <Form.Group>
                  <Form.Select size="lg" placeholder="Enter Title">
                    <option>Furniture</option>
                    <option value="decoration">Decoration</option>
                    <option value="babyandchildren">Baby & Children</option>
                  </Form.Select>
                </Form.Group>
              </Form>
              <button className="button_main">Save</button>
            </div>
            <div className={styles.upload}>
              <img src="/images/icons/upload.svg" />
              <div>
                <h3 className="h3_main">Select a file or drag and drop here</h3>
                <p className="para_main">
                  JPG, PNG or PDF, file size no more than 10MB
                </p>
              </div>
              <button className="button_main">SELECT FILE</button>
            </div>
          </div>
          <div className={styles.body}>
            <h1 className="h2_main pb-5">Services Management</h1>
            <ServicesTable image Data={Data} />
          </div>
          <Pagination />
        </div>
      </div>
    </>
  );
};

export default ServicesManagementAddScreen;
