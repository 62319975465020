import React from "react";
import { Table } from "react-bootstrap";
import styles from "./DynamicTable.module.css";
import { Link } from "react-router-dom";

const DynamicTable = ({ serviceProvider, Data, jobsManagement }) => {
  return (
    <Table className={styles.table} striped>
      <thead>
        <tr>
          <th>#</th>
          <th>{serviceProvider ? "Service Provider" : jobsManagement ? "Job ID" : "Customer Name"}</th>
          <th>{jobsManagement ? "Job Category" : "Phone Number"}</th>
          <th>Status</th>
          <th>View</th>
        </tr>
      </thead>
      <tbody>
        {Data.map((item) => (
          <tr>
            <td className={styles.checkBox}>
              <input type="checkbox" />
            </td>
            <td className={styles.customerName}>{item.name}</td>
            <td className={styles.phoneNum}>{item.phone}</td>
            <td className={styles.status}>
              <select className={styles.customSelect}>
                <option value="active">Active</option>
                <option value="pending">Pending</option>
                <option value="completed">Completed</option>
              </select>
            </td>
            <td className={styles.view}>
              <Link to={item.link}>
              <img src="/images/icons/eyeblue.svg" />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DynamicTable;
