export const Data = [
  {
    img: "/images/carmer.svg",
    name: "Carmen Parksouth",
    text: " I want someone to help me with mounting TV, there will be two mountings .....",
    active: true,
  },
  {
    img: "/images/admin.svg",
    name: "Heidi Turner",
    text: " I want someone to help me with mounting TV, there will be two mountings .....",
  },
  {
    img: "/images/admin.svg",
    name: "Wade Warren",
    text: " I want someone to help me with mounting TV, there will be two mountings .....",
  },
  {
    img: "/images/admin.svg",
    name: "Jenny Wilson",
    text: " I want someone to help me with mounting TV, there will be two mountings .....",
  },
  {
    img: "/images/admin.svg",
    name: "Carmen Parksouth",
    text: " I want someone to help me with mounting TV, there will be two mountings .....",
  },
];
