import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import ServicesTable from "../../../components/ServicesTable/ServicesTable";
import Pagination from "../../../components/UI Helpers/Pagination/Pagination";
import { Data } from "./ServicesData";
import styles from "./ServicesManagementEditScreen.module.css";
import { Form } from "react-bootstrap";

const ServicesManagementEditScreen = () => {
  const [isActive, setIsActive] = useState(true);

  return (
    <>
      <Header getIsActive={(e) => setIsActive(e)} />
      <div
        style={{
          width: "100%",
          height: "92vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sidebar barActive={isActive} />
        <div className={styles.main}>
          <div className={styles.head}>
            <h1 className="h2_main text-start align-self-start">
              Services Category
            </h1>
            <div className={styles.inputCont}>
              <Form className={styles.form}>
                <Form.Group>
                  <Form.Label>Service Title</Form.Label>
                  <Form.Control type="text" placeholder="Enter Title" />
                </Form.Group>
              </Form>
              <button className="button_main">Save</button>
            </div>
          </div>
          <div className={styles.body}>
            <h1 className="h2_main pb-5">Services Management</h1>
            <ServicesTable Data={Data} />
          </div>
          <Pagination />
        </div>
      </div>
    </>
  );
};

export default ServicesManagementEditScreen;
