import React from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { Container } from "react-bootstrap";
import CustomerDetails from "../../../components/CustomerDetails/CustomerDetails";
import styles from "./CustomerScreenView.module.css";
import { Link } from "react-router-dom";
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import { Data } from "./data";
import Pagination from "../../../components/UI Helpers/Pagination/Pagination";

const CustomerScreenView = () => {
  return (
    <>
      <Header />
      <div
        style={{
          width: "100%",
          height: "92vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sidebar />
        <div className={styles.main}>
          <Container className={styles.maindiv}>
            <div className={`${styles.iconBack} pb-4`}>
              <Link to="/dashboard/customer">
                <img
                  src="/images/icons/arrowleft.svg"
                  style={{
                    width: "24px",
                    height: "24px",
                    color: "#000000",
                  }}
                  alt=""
                />
              </Link>
              <h3 className="h3_main">Customer</h3>
            </div>
            <CustomerDetails />
            <div className={styles.body}>
            <div className={styles.tabMain}>
              <h2 className="h2_main">Jobs History</h2>
              <div className={styles.tabsCont}>
                <div className={styles.tab}>Pending</div>
                <div className={`${styles.tab} ${styles.tabActive}`}>
                  Scheduled
                </div>
                <div className={styles.tab}>Completed</div>
              </div>
            </div>
            <DynamicTable jobsManagement Data={Data} />
          </div>
          <Pagination />
          </Container>
         
        </div>
      </div>
    </>
  );
};

export default CustomerScreenView;
