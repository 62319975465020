export const Data = [
    {
      name: "Mark Wick",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "April Curtis",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "Mike Torello",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "John",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "William",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "Tony Danza",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "Tony Danza",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "Tony Danza",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "Tony Danza",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "Tony Danza",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "Tony Danza",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
    {
      name: "Tony Danza",
      phone: "(441) 645-8966",
      status: "",
      link: "/dashboard/serviceproviders/view",
    },
  ];
  