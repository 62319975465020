import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import Pagination from "../../../components/UI Helpers/Pagination/Pagination";
import styles from "./ServiceProviderScreen.module.css";
import { Data } from "./ServiceProviderData";
import FiltersPopup from "../../../components/UI Helpers/FiltersPopup/FiltersPopup";

const ServiceProviderScreen = () => {
  const [isActive, setIsActive] = useState(true);
  const [filters, setFilters] = useState(false);

  return (
    <>
      <Header getIsActive={(e) => setIsActive(e)} />
      <div
        style={{
          width: "100%",
          height: "92vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sidebar barActive={isActive} />
        <div className={styles.main}>
          <div className={styles.head}>
            <h1 className="h2_main text-start">Service Providers</h1>
            <div className={styles.buttonCont}>
              <div className={styles.csv}>
                <img src="/images/icons/csv.svg" />
                <h3 className="h3_main">CSV File</h3>
              </div>
              <div
                onClick={() => setFilters(true)}
                className={styles.headButton}
              >
                Pending <img src="/images/icons/chevron_down_white.svg" />
              </div>
            </div>
          </div>
          <div className={styles.body}>
            <DynamicTable serviceProvider Data={Data} />
          </div>
          <Pagination />
        </div>
        {filters && <FiltersPopup close={(e) => setFilters(e)} />}
      </div>
    </>
  );
};

export default ServiceProviderScreen;
