export const Data = [
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
    {
      serviceName: "Furniture",
      img: "/images/admin.svg",
    },
  ];
  