import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import styles from "./MainScreen.module.css";
import { Container } from "react-bootstrap";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import DashboardSummary from "../../../components/UI Helpers/DashboardSummary/DashboardSummary";
import CalendarPopup from "../../../components/UI Helpers/CalendarPopup/CalendarPopup";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart Text",
    },
  },
};

const labels = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Lorem",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
      borderColor: "#CE2A96",
      backgroundColor: "#CE2A96",
    },
    {
      label: "Ispum",
      data: labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
      borderColor: "#6C60FF",
      backgroundColor: "#6C60FF",
    },
  ],
};

const MainScreen = () => {
  const [isActive, setIsActive] = useState(true);
  const [calendar, setCalendar] = useState(false);

  return (
    <>
      <Header getIsActive={(e) => setIsActive(e)} />
      <div
        style={{
          width: "100%",
          height: "92vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sidebar barActive={isActive} />
        <div className={styles.main}>
          <div className={styles.head}>
            <h1 className="h2_main">What's happening on Kadom!</h1>
            <div
              onClick={() => setCalendar(true)}
              className={styles.headButton}
            >
              This Week <img src="/images/icons/chevron_down.svg" />
            </div>
          </div>
          <div className={styles.body}>
            <div>
              <Line
                options={options}
                data={data}
              />
            </div>
          </div>
          <div className={styles.footer}>
            <DashboardSummary />
          </div>
          {calendar && <CalendarPopup close={(e) => setCalendar(e)} />}
        </div>
      </div>
    </>
  );
};

export default MainScreen;
