import React, { useState } from "react";
import Header from "../../../components/Header/Header";
import Sidebar from "../../../components/Sidebar/Sidebar";
import styles from "./JobsManagementScreen.module.css";
import CalendarPopup from "../../../components/UI Helpers/CalendarPopup/CalendarPopup";
import DynamicTable from "../../../components/DynamicTable/DynamicTable";
import Pagination from "../../../components/UI Helpers/Pagination/Pagination";
import { Data } from "./JodsData";

const JobsManagementScreen = () => {
  const [isActive, setIsActive] = useState(true);
  const [calendar, setCalendar] = useState(false);

  return (
    <>
      <Header getIsActive={(e) => setIsActive(e)} />
      <div
        style={{
          width: "100%",
          height: "92vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sidebar barActive={isActive} />
        <div className={styles.main}>
          <h1 className="h2_main text-start">Job Management</h1>
          <div style={{width:"100%"}}>
            <div className={styles.head}>
              <div className={styles.search}>
                <input placeholder="Search with Job ID" type="text" />
                <img src="/images/icons/search.svg" />
              </div>
              <div
                onClick={() => setCalendar(true)}
                className={styles.headButton}
              >
                This Week <img src="/images/icons/chevron_down.svg" />
              </div>
            </div>
            <div className={`${styles.tab} mt-3`}>
              <h3 className={`h3_main ${styles.tabActive}`}>All Jobs</h3>
              <h3 className={`h3_main`}>Pending</h3>
              <h3 className={`h3_main`}>Scheduled</h3>
              <h3 className={`h3_main`}>Completed</h3>
            </div>
          </div>
          <div className={styles.body}>
            <DynamicTable Data={Data} jobsManagement />
          </div>
          <Pagination />
        </div>
        {calendar && (
          <CalendarPopup marginTop="130px" marginRight="30px"  close={(e) => setCalendar(e)} />
        )}
      </div>
    </>
  );
};

export default JobsManagementScreen;
